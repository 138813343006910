<template>
  <div class="login">
    <div class="login-container">
      <div>
        <img class="login-logo" src="../assets/img/logo.png" />
        <div class="login-top">
          <label class="login-title">Recuperar contraseña</label>
        </div>
        <transition mode="out-in">
          <div v-if="seccion == 'inicio'">
            <div class="login-row">
              <img
                class="login-label-img"
                src="../assets/img/icon/candado.png"
              />
              <label class="login-label" for="password">Contraseña</label>
              <label class="login-error" v-show="errorPassword != ''">{{
                errorPassword
              }}</label>
              <div
                class="input-primary"
                :class="{ 'input-error': errorPassword != '' }"
              >
                <input
                  id="password"
                  :type="passwordType"
                  v-model="password"
                  placeholder="Ingresa tu contraseña"
                />
                <img
                  v-show="passwordType == 'password'"
                  class="input-img"
                  src="../assets/img/icon/ojo.png"
                  @click="passwordType = 'text'"
                />
                <img
                  v-show="passwordType == 'text'"
                  class="input-img"
                  src="../assets/img/icon/ojo-active.png"
                  @click="passwordType = 'password'"
                />
              </div>
            </div>
            <div class="login-row">
              <img
                class="login-label-img"
                src="../assets/img/icon/candado.png"
              />
              <label class="login-label" for="password">Contraseña</label>
              <label class="login-error" v-show="errorPassword2 != ''">{{
                errorPassword2
              }}</label>
              <div
                class="input-primary"
                :class="{ 'input-error': errorPassword2 != '' }"
              >
                <input
                  id="password"
                  :type="passwordType"
                  v-model="password2"
                  placeholder="Ingresa tu contraseña"
                />
                <img
                  v-show="passwordType == 'password'"
                  class="input-img"
                  src="../assets/img/icon/ojo.png"
                  @click="passwordType = 'text'"
                />
                <img
                  v-show="passwordType == 'text'"
                  class="input-img"
                  src="../assets/img/icon/ojo-active.png"
                  @click="passwordType = 'password'"
                />
              </div>
            </div>
            <button
              class="button-primary login-btn"
              @click="chequearPassword()"
            >
              Continuar
            </button>
          </div>
          <div v-else-if="seccion == 'fin'">
            <img
              class="login-recuperar-check"
              src="../assets/img/login/check.png"
            />
            <p class="login-recuperar-text">
              ¡Contraseña reestablecida correctamente!
            </p>
            <label class="login-recuperar-ingresar" @click="ingresar()"
              >Ingresar ahora</label
            >
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecuperarPassword",
  components: {},
  data() {
    return {
      seccion: "inicio",
      password: "",
      password2: "",
      errorPassword: "",
      errorPassword2: "",
      passwordType: "password",
    };
  },
  mounted() {},
  methods: {
    chequearPassword() {
      let continuar = true;
      if (this.password == "" || this.password == null) {
        this.errorPassword = "Debe ingresar una contraseña";
        continuar = false;
      } else {
        this.errorPassword = "";
      }
      if (this.password2 == "" || this.password2 == null) {
        this.errorPassword2 = "Debe ingresar una contraseña";
        continuar = false;
      } else {
        this.errorPassword2 = "";
      }
      if (continuar) {
        if (this.password != this.password2) {
          this.errorPassword = "Las contraseñas no coinciden";
          this.errorPassword2 = "Las contraseñas no coinciden";
        } else {
          var that = this;
          let json = {
            token: this.$route.params.url,
            password: this.password,
          };
          this.$axios
            .put(this.$serverURL + "/api/user/changepass/", json)
            .then(function (response) {
              console.log(response.data);
              localStorage.setItem("$token", response.data.token);
              that.$axios.defaults.headers.common["Authorization"] =
                "Token " + response.data.token;
              that.$emitter.emit("logged");
              that.seccion = "fin";
            })
            .catch(function () {
              that.errorPassword = "Error cambiando la contraseña";
              that.errorPassword2 = "Error cambiando la contraseña";
            });
        }
      }
    },
    ingresar() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" src="@/assets/css/views/login.scss"></style>
